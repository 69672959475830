<template>
  <div class="list">
    <template v-if="runs.length">
      <v-container>
        <v-row>
          <v-col cols="12" class="pb-4 pt-0">
            <span style="color: #3E6B86;"></span>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12" class="py-0 mb-7">
            <div class="run-title mb-4">
              <span>{{ $t("runs.today") }}</span>
              <span class="count">{{ todayRuns.length }}</span>
            </div>
            <div class="list-head pt-1 mb-4">
              <div style="color: #3E6B86;" v-if="!todayRuns.length">{{ $t("runs.nonetoday") }}</div>
              <template v-else>
                <div class="date">
                  {{ $d($moment(new Date), 'long') }}
                </div>
                <dropoff-point
                  :dropoff="todayRuns[0].dropoff"
                  :dropoff-list="todayRuns[0].dropofflist"
                  :date="todayRuns[0].date"
                />
              </template>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="cards-list">
        <v-row v-for="run in todayRuns" :key="run.id">
          <v-col cols="12" class="py-0 mb-4">
            <div class="card-wrapper" :class="{'removable':isRunRemoving}">
              <div class="remove-btn">
                <input :id="run.id" :value="run" v-model="selected" type="checkbox">
                <label v-if="run.collectedAmount != 0" :for="run.id"></label>
              </div>
              <run-card :run="run" :light="true" :link="true" />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="cards-list">
        <v-row>
          <v-col cols="12" class="py-0 mb-4">
            <div class="run-title">
              <span>{{ $t("runs.upcoming") }}</span>
              <span class="count">{{ upcomingsCount }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row v-for="(group, index) in upcomingRuns" :key="index">
          <v-col cols="12" class="pb-0 pt-1 mb-4">
            <div class="list-head">
              <div class="date">
                {{ $d($moment(group.date), 'long') }}
              </div>
              <dropoff-point
                :dropoff="group.runs[0].dropoff"
                :dropoff-list="group.runs[0].dropofflist"
                :date="group.runs[0].date"
              />
            </div>
          </v-col>
          <v-col cols="12" class="py-0 mb-4" v-for="run in group.runs" :key="run.id">
            <div class="card-wrapper" :class="{'removable':isRunRemoving}">
              <div class="remove-btn">
                <input :id="run.id" :value="run" v-model="selected" type="checkbox">
                <label v-if="run.collectedAmount != 0" :for="run.id"></label>
              </div>
              <run-card :run="run" :light="true" :link="true"/>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <empty-page v-else-if="($store.getters.isVolunteer || $store.getters.isLeader)" :page="page" />
  </div>
</template>

<script>
import _ from 'lodash';
import * as runService from '@/services/run';
import RunCard from '../../components/run-card';
import EmptyPage from '../../components/empty-page';
import DropoffPoint from '@/components/dropoff-point';

export default {
  name: 'runs',
  timer: '',
  metaInfo: {
    title: 'Runs'
  },
  components: { RunCard, EmptyPage, DropoffPoint },
  data () {
    return {
      selected: [],
      date: null,
      status: null,
      page: {
        image: '/images/runs-scheduled.svg',
        title: this.$t('runs.title'),
        subTitle: this.$t('runs.sub')
      },
      filters: {}
    };
  },
  created () {
    this.$bus.$on('runs-deleted', () => {
      this.selected.map(run => {
        runService.unclaim(run.id)
          .then(() => {
            this.getRuns();
          });
      });
      this.$store.commit('setRemovingRuns', false);
      this.selected = [];
    });
    // TODO Make refresh interval an env variable (and use it everywhere it's used)
    this.timer = setInterval(this.getRuns, 60000);
  },
  computed: {
    isRunRemoving () {
      return this.$store.getters.isRunRemoving;
    },
    upcomingRuns () {
      let runs = this.runs.filter(run => run.date !== this.$moment().format('YYYY-MM-DD'));
      if (this.isRunRemoving) {
        runs = runs.filter(run => !run.collectedAmount);
      }
      // this gives an object with dates as keys
      const groups = runs.reduce((groups, run) => {
        const date = run.date;
        if (!groups[date]) {
          groups[date] = [];
        }
        groups[date].push(run);
        return groups;
      }, {});

      let result = Object.keys(groups).map((date) => {
        return {
          date,
          runs: groups[date]
        };
      });
      return _.sortBy(result, 'date');
    },
    upcomingsCount () {
      return this.upcomingRuns.reduce(function (count, group) {
        return count + group.runs.length;
      }, 0);
    },
    todayRuns () {
      let runs = this.$store.getters.todayRuns;
      if (this.isRunRemoving) {
        runs = runs.filter(run => !run.collectedAmount);
      }
      return runs;
    },
    dates () {
      const dateArr = [];
      const today = new Date();
      for (let i = 0; i < 4; i++) {
        let nextDay = today.setDate(new Date().getDate() + i);
        dateArr.push({
          text: i === 0 ? 'Today' : this.$moment(nextDay).format('ddd, D MMM'),
          value: this.$moment(nextDay).format('YYYY-MM-DD')
        });
      }
      return dateArr;
    },
    runs () {
      return this.$store.getters.myRuns;
    }
  },
  methods: {
    getRuns () {
      this.$store.dispatch('loadRuns', this.filters);
    }
  },
  watch: {
    selected () {
      this.$store.commit('setSelectedRuns', this.selected);
    },
    filters: {
      handler () {
        this.getRuns();
        this.date = this.$moment(this.filters.date).format('YYYYMMDD');
      },
      deep: true
    }
  },
  beforeDestroy () {
    this.$bus.$off('runs-deleted');
    clearInterval(this.timer);
  }
};
</script>
